import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";

const rootElement = document.getElementById("root");
ReactDOM.render(
	<React.Suspense fallback={<div>Loading</div>}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</React.Suspense>,
	rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
