import React from "react";
import styled from "styled-components";
import Map from "./Map";

import { useTranslation } from "react-i18next";

const Footer = () => {
	const { t, i18n } = useTranslation();
	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};
	return (
		<>
			<StyledFooter>
				<div lang={i18n.language} className="footer--container">
					<h3>{t("Footer.title")}</h3>
					<ul className="info">
						<li>{t("Footer.Info.info2")}</li>
						<li>{t("Footer.Info.info3")}</li>
						<li>{t("Footer.Info.info4")}</li>
						{/* <li>{t("Footer.Info.info1")}</li> */}
						{/* <li>{t("Footer.Info.info5")}</li> */}
					</ul>
					<ul className="contacts">
						<li>
							<a href="mailto:info@tenutalapicola.it">info@tenutalapicola.it</a>
						</li>
						<li>
							<a
								className="address"
								href="https://www.google.com/maps/place/Via+Picola,+1,+84010+Furore+SA/data=!4m2!3m1!1s0x133b971128f2a509:0xe19e9db45003083?sa=X&ved=2ahUKEwjX5bjU7In3AhUJR_EDHQdcDl0Q8gF6BAgUEAE">
								<p>
									Tenuta La Picola <br /> Via Picola, 1 <br /> 84010 Furore (SA)
								</p>
							</a>
						</li>
						<li>
							<a href="tel:00393343715388">+39 334 371 5388</a>
						</li>
					</ul>
					<ul className="languageSelector">
						<li onClick={() => changeLanguage("it")}>IT</li>
						<li onClick={() => changeLanguage("en")}>EN</li>
					</ul>
				</div>
				<div lang={i18n.language} className="footer--container footer--map">
					<Map zoom={15} center={""} />
				</div>
			</StyledFooter>
			<Copyright>
				<p>P.IVA IT05921770656</p>
				<p>
					Made with ❤️ by{" "}
					<a target="_blank" rel="noreferrer" href="https://www.stayerk.me">
						StayErk
					</a>
				</p>
			</Copyright>
		</>
	);
};

const Copyright = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 0.5em 0;
	a {
		text-decoration: none;
		font-weight: bold;
		box-shadow: inset 0 0 0 0 white;
		color: var(--primary-blue);
		/*margin: 0 -0.25rem;
		padding: 0 0.25rem;*/
		transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
	}

	a:hover {
		box-shadow: inset 200px 0 0 0 var(--primary-blue);
		color: white;
	}
`;

const StyledFooter = styled.footer`
	display: flex;
	color: white;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100vh;
	text-align: center;
	justify-content: space-around;
	background-color: var(--primary-blue);
	@media screen and (min-width: 900px) {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		min-height: 455px;
		height: 55vh;
	}

	h3 {
		font-size: 2em;
	}

	.footer--container {
		padding: 2em;
		width: 100%;
	}

	.footer--map {
		padding: 0;
		width: 100%;
		height: 100%;
	}

	.info {
		list-style: none;
		text-align: center;
		line-height: 2em;
		width: 100%;
	}

	.contacts {
		list-style: none;
		text-align: center;
		line-height: 1.3em;
		width: 100%;
	}

	.languageSelector {
		margin-top: 1em;
		list-style: none;
		display: flex;
		justify-content: space-around;
		align-items: center;

		li {
			cursor: pointer;
		}

		li:hover {
			font-weight: bold;
		}
	}

	li {
		margin-top: 0.5em;
	}

	a {
		text-decoration: none;
		font-weight: bold;
		box-shadow: inset 0 0 0 0 var(--primary-blue);
		color: white;
		margin: 0 -0.25rem;
		padding: 0 0.25rem;
		transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
	}

	a:hover {
		box-shadow: inset 200px 0 0 0 white;
		color: var(--primary-blue);
	}
	.address:hover,
	.address {
		all: unset;
		font-weight: bold;
		text-decoration: underline;
		color: white;
		cursor: pointer;
	}
`;

export default Footer;
