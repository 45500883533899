import React from "react";

import styled from "styled-components";

const VerticalCard = ({ img, alt, text }) => {
	return (
		<StyledCard className="single">
			<img src={img} alt={alt} />
			<h2>{text}</h2>
		</StyledCard>
	);
};

const StyledCard = styled.div`
	display: flex;
	flex-direction: column;
	-webkit-box-shadow: 2px 7px 21px -10px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 2px 7px 21px -10px rgba(0, 0, 0, 0.75);
	box-shadow: 2px 7px 21px -10px rgba(0, 0, 0, 0.75);

	img {
		display: block;
		aspect-ratio: 1/1;
		width: 100%;
		object-fit: cover;
		max-height: 300px;
	}

	h2 {
		color: var(--primary-blue);
		padding: 1em 2em;
		text-align: center;
	}
`;

export default VerticalCard;
