import React from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";

import logo from "../assets/LogoLaPicola1.jpeg";
import CTA from "./CTA";

const Header = ({ img }) => {
	const { t, i18n } = useTranslation();
	return (
		<StyledSection img={img}>
			<div className="header">
				<img src={logo} alt="Logo Tenuta La Picola" />
			</div>
			<div lang={i18n.language} className="motto">
				<h1>{t("Home.motto")}</h1>
		      	<p><Trans i18nKey="Home.submotto"  /></p>
				<CTA
					margin={"0em"}
					className="button"
					link={
						"https://booking.ericsoft.com/BookingEngine/Book?idh=3063E7C11030827C"
					}
					text={"Home.button"}
				/>
			</div>
		</StyledSection>
	);
};

const StyledSection = styled.section`
	height: 100vh;

	.header {
		height: 70%;
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-image: ${(props) => "url(" + props.img + ")"};
	}

	.motto {
		text-align: center;
		height: 30%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;

		h1 {
			font-size: clamp(0.75em, 2vw + 0.3em, 2.5em);
		}

		p {
			max-width: 90%;
		}
	}

	img {
		min-width: 75%;
		max-width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		color: var(--primary-blue);
		padding: 1em 2em;
		transform: translate(-50%, -50%);
	}

	@media screen and (min-width: 900px) {
		img {
			min-width: unset;
			width: 30%;
			top: unset;
			bottom: 0;
			left: 0;
			transform: unset;
			padding: 0;
			display: block;
		}
	}
`;

export default Header;
