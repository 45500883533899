import React from "react";
import Header from "../Components/Header";
import HorizontalCard from "../Components/HorizontalCard";
import VerticalCard from "../Components/VerticalCard";
import CTA from "../Components/CTA";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import fotoHeader from "../assets/header.jpg";
import fotoContent from "../assets/content.jpg";
import fotoNjulinella from "../assets/appartamenti/njulinella.jpg";
import fotoGennarino from "../assets/appartamenti/gennarino.jpg";
import fotoSufinellla from "../assets/appartamenti/sufinella.jpg";
import fotoStandard from "../assets/camere/Camera 1.jpeg";
import fotoIdro from "../assets/camere/Camera 3.jpeg";
import fotoEconomy from "../assets/camere/standard.jpg";
import fotoSuperior from "../assets/camere/Camera 2.jpeg";

const Home = () => {
	const { t, i18n } = useTranslation();

	return (
		<>
			<Header img={fotoHeader} />
			<StyledSection className="info">
				<div lang={i18n.language} className="content">
					<p>{t("Home.content")}</p>
					<img src={fotoContent} alt="Panorama" />
				</div>
			</StyledSection>

			<StyledSection>
				<GridLayout className="container">
					<div className="single text-section" lang={i18n.language}>
						<h2>{t("Home.camere")}</h2>
						<CTA
							link={
								"https://booking.ericsoft.com/BookingEngine/Book?idh=3063E7C11030827C"
							}
							text={t("Home.button")}
						/>
					</div>
					<HorizontalCard
						img={fotoSuperior}
						alt={"Foto Camere"}
						text={t("Home.vascaIdro")}
						double
					/>

					<VerticalCard
						img={fotoStandard}
						alt={"Foto Standard"}
						text={"Matrimoniale Standard"}
					/>
					<VerticalCard
						img={fotoIdro}
						alt={"Foto Economy"}
						text={"Standard Tripla"}
					/>
					<VerticalCard
						img={fotoEconomy}
						alt={"Foto Economy"}
						text={"Economy"}
					/>
				</GridLayout>
			</StyledSection>
			<StyledSection id="appartamenti">
				<GridLayout className="container">
					<div lang={i18n.language} className="single text-section">
						<h2>{t("Home.appartamenti")}</h2>
						<CTA
							link={
								"https://booking.ericsoft.com/BookingEngine/Book?idh=3062E7C11030827C"
							}
							text={"Home.button"}
						/>
					</div>
					<HorizontalCard
						img={fotoNjulinella}
						alt={"Appartamento Njulinella"}
						text={"Njulinella"}
						double
					/>
					<HorizontalCard
						img={fotoGennarino}
						alt={"Appartamento Gennarino"}
						text={"Gennarino"}
						single
					/>
					<HorizontalCard
						img={fotoSufinellla}
						alt={"Appartamento Sufinella"}
						text={"Sufinella"}
						single
					/>
				</GridLayout>
			</StyledSection>
		</>
	);
};

const StyledSection = styled.section`
	margin: 2em 0;

	.content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		img {
			display: none;
			height: 100%;
			width: 100%;
			max-height: 500px;
			object-fit: cover;
		}

		p {
			grid-column: span 2;
			padding: 0 3em;
			text-align: center;
			font-size: 1.25em;
		}

		@media screen and (min-width: 900px) {
			img {
				display: block;
			}

			p {
				grid-column: unset;
			}
		}
	}
`;

const GridLayout = styled.div`
	display: grid;
	gap: 1em;
	grid-template-columns: 1fr;

	.text-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h2 {
			font-size: 2em;
		}
	}

	@media screen and (min-width: 900px) {
		.third {
			grid-column: span 4;
			max-height: 500px;
		}

		.half {
			grid-column: span 3;
			max-height: 500px;
		}

		.single {
			grid-column: span 2;
			max-height: 500px;
		}

		.atom {
			grid-column: span 1;
		}

		grid-template-columns: repeat(6, 1fr);
	}
`;

export default Home;
