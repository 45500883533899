import React from "react";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";

const Marker = () => {
	return <StyledPin>📌</StyledPin>;
};

const Map = ({ center, zoom }) => {
	return (
		<StyledDiv>
			<GoogleMapReact
				bootstrapURLKeys={{ key: "AIzaSyCgLCAKYtcm7tdTfoqYIK60PHNu7QF7z3A" }}
				defaultCenter={{
					lat: 40.6176,
					lng: 14.5411,
				}}
				defaultZoom={zoom}>
				<Marker lat={40.61829} lng={14.54112} />
			</GoogleMapReact>
		</StyledDiv>
	);
};

const StyledDiv = styled.div`
	height: 100%;
	width: 100%;
`;

const StyledPin = styled.p`
	font-size: 2em;
`;

export default Map;
