import React from "react";

import styled from "styled-components";

const HorizontalCard = ({ img, alt, text, double, single }) => {
	return (
		<StyledDiv className={double ? "third" : single ? "half" : "single"}>
			<img src={img} alt={alt} />
			<h2>{text}</h2>
		</StyledDiv>
	);
};

const StyledDiv = styled.div`
	width: 100%;
	position: relative;
	-webkit-box-shadow: 2px 7px 21px -10px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 2px 7px 21px -10px rgba(0, 0, 0, 0.75);
	box-shadow: 2px 7px 21px -10px rgba(0, 0, 0, 0.75);
	img {
		aspect-ratio: 1/1;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	h2 {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		text-align: center;
		background-color: white;
		color: var(--primary-blue);

		@media screen and (min-width: 900px) {
			bottom: 0;
			right: 0;
			padding: 1em 2em;
			width: max-content;
		}
	}
`;

export default HorizontalCard;
