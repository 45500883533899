import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

const CTA = ({ link, text, margin }) => {
	const { t } = useTranslation();
	return (
		<StyledLink margin={margin} href={link}>
			{t(text)}
		</StyledLink>
	);
};

const StyledLink = styled.a`
	display: block;
	color: white;
	padding: 1em 2em;
	background-color: var(--primary-blue);
	text-align: center;
	width: max-content;
	text-decoration: none;
	margin-top: ${(props) => props.margin || "2em"};
	transition: all 0.3s ease-in;

	&:hover,
	&:focus {
		text-decoration: none;
		-webkit-box-shadow: 2px 7px 10px 0px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 2px 7px 10px 0px rgba(0, 0, 0, 0.35);
		box-shadow: 2px 7px 10px 0px rgba(0, 0, 0, 0.35);
	}
`;

export default CTA;
