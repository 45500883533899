import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Home from "./Pages/Home";
import Footer from "./Components/Footer";
function App() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{t("Meta.title")}</title>
				<meta name="description" content={t("Meta.description")}></meta>
				<meta
					name="keywords"
					content="amalfi coast, where to stay, furore, capri, positano, sea view"></meta>
			</Helmet>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</>
	);
}

export default App;
